import React from "react";

const Container = ({ children }) => (
  <div className="container-fluid p-0">{children}</div>
);

const Row = ({ children }) => <div className="row">{children}</div>;

const Col = ({ children }) => <div className="col text-nowrap">{children}</div>;

export { Container, Row, Col };
