import React from "react";
import "./calendar.css";

const Calendar = ({ firstDay }) => (
  <div className="calendar">
    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(v => (
      <Week
        firstDay={firstDay
          .clone()
          .add(-firstDay.day(), "days")
          .add(v, "weeks")}
        posTop={v === 0}
        posBottom={v === 12}
        key={v}
      />
    ))}
  </div>
);

const Week = ({ firstDay, posTop, posBottom }) => {
  let starting =
    firstDay.month() !==
    firstDay
      .clone()
      .add(6, "days")
      .month();
  let ending = false;
  return (
    <div className="calendar-week">
      {[0, 1, 2, 3, 4, 5, 6].map(v => {
        const day = firstDay.clone().add(v, "days");
        const style: any = {};
        if (day.date() === 1) {
          ending = true;
          starting = false;
        }
        if (posTop || ending) {
          style.borderTopColor = "black";
        }
        if (posBottom || starting) {
          style.borderBottomColor = "black";
        }
        if (day.date() === 1 || v === 0) {
          style.borderLeftColor = "black";
        }
        if (v === 6) {
          style.borderRightColor = "black";
        }
        if (day.day() === 0 || day.day() === 6) {
          style.color = "lightcoral";
        }
        return <Day day={day} style={style} key={v} />;
      })}
    </div>
  );
};

const Day = ({ day, style }) => (
  <div className="calendar-day" style={style}>
    <button className="calendar-day-button">
      <div className="calendar-day-month-label">
        &nbsp;{day.date() === 1 ? day.format("MMM") : ""}&nbsp;
      </div>
      <div className="calendar-day-label">{day.date()}</div>
    </button>
  </div>
);

export { Calendar };
