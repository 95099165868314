import * as React from "react";
import Layout from "../../components/Layout";

function BlankPage(): React.Node {
  return (
    <Layout title="Blank">
      <h1>Blank</h1>
    </Layout>
  );
}

export default BlankPage;
