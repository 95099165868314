if (typeof window !== "undefined") {
  // Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-line
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "UA-124206632-1");

  // Google AdSense
  window.adsbygoogle = window.adsbygoogle || [];
  window.adsbygoogle.push({
    google_ad_client: "ca-pub-9677592760506556",
    enable_page_level_ads: true
  });
}

export default null;
