import React from "react";
import Layout from "../../components/Layout";

class ExpressionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expressionX: "5 * 10 - 3",
      expressionY: "round( pow(PI * x, 2) )",
      expressionZ: "floor( log(x) + sqrt(y) + E )",
      expression: "[x, hex(18 * y), bin(z)]"
    };

    this.handleChangeX = expressionX => this.setState({ expressionX });
    this.handleChangeY = expressionY => this.setState({ expressionY });
    this.handleChangeZ = expressionZ => this.setState({ expressionZ });
    this.handleChange = expression => this.setState({ expression });
  }

  render() {
    const { expression, expressionX, expressionY, expressionZ } = this.state;
    const vars = {};
    let result = "Error";
    let error = "";
    try {
      vars.x = evalEx(expressionX, vars);
      vars.y = evalEx(expressionY, vars);
      vars.z = evalEx(expressionZ, vars);
      result = evalEx(expression, vars);
    } catch (e) {
      error = "" + e;
    }

    return (
      <Layout title="Numerical Expression">
        <TextEx
          prepend="x="
          value={expressionX}
          append={vars.x}
          onChange={this.handleChangeX}
        />
        <TextEx
          prepend="y="
          value={expressionY}
          append={vars.y}
          onChange={this.handleChangeY}
        />
        <TextEx
          prepend="z="
          value={expressionZ}
          append={vars.z}
          onChange={this.handleChangeZ}
        />
        <TextEx
          value={expression}
          append={result}
          onChange={this.handleChange}
        />
        {error ? <div className="alert alert-warning">{error}</div> : null}
      </Layout>
    );
  }
}

function lengthLog2(l, min) {
  return Math.max(Math.pow(2, Math.ceil(Math.log2(l))), min);
}

const Ops = {
  hex: v => {
    const r = v.toString(16).toUpperCase();
    // pad to 2, 4, 8, 16, 32 etc
    let pad = "";
    if (r.length < 32) {
      const l = lengthLog2(r.length, 2);
      pad = "0".repeat(l - r.length);
    }
    return "0x" + pad + r;
  },
  bin: v => {
    const r = v.toString(2).toUpperCase();
    // pad to 8, 16, 32 etc
    let pad = "";
    if (r.length < 32) {
      const l = lengthLog2(r.length, 8);
      pad = "0".repeat(l - r.length);
    }
    return pad + r;
  }
};

function evalEx(expression, vars) {
  const parsed = expression.replace(/[_a-z]+[_a-z0-9.]*/gi, m => {
    const a = m.split(".");
    const s = a[0];
    if (vars[s] !== undefined) {
      a.unshift("vars");
    } else if (Ops[s] !== undefined) {
      a.unshift("Ops");
    } else if (Math[s] !== undefined) {
      a.unshift("Math");
    }
    return a.join(".");
  });
  return eval(parsed); // eslint-disable-line
}

class TextEx extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = e => {
      this.props.onChange(e.target.value);
    };
  }

  render() {
    const { value, prepend, append } = this.props;
    return (
      <div className="form-group">
        <div className="input-group">
          {prepend ? (
            <div className="input-group-prepend">
              <div className="input-group-text">{prepend}</div>
            </div>
          ) : null}
          <input
            type="text"
            className="form-control"
            value={value}
            onChange={this.handleChange}
            spellCheck="false"
          />
          {append !== undefined ? (
            <div className="input-group-append">
              <div className="input-group-text font-weight-bold">
                {JSON.stringify(append)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ExpressionPage;
