import React from "react";
import currencyFormatter from "currency-formatter";
import Layout from "../../components/Layout";
import { Row, Col } from "../../components/Grid";
import Text from "../../components/Text";
import Section from "../../components/Section";
import Select from "../../components/Select";
import classnames from "classnames";

const MONTHS = {
  "0": "Jan",
  "1": "Feb",
  "2": "Mar",
  "3": "Apr",
  "4": "May",
  "5": "Jun",
  "6": "Jul",
  "7": "Aug",
  "8": "Sep",
  "9": "Oct",
  "10": "Nov",
  "11": "Dec"
};

class LoanPage extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      amount: "400,000.00",
      rate: "4.5",
      term: "30",
      startYear: "" + now.getFullYear(),
      startMonth: "" + now.getMonth(),
      showAmortization: false
    };

    this.handleChangeAmount = amount => this.setState({ amount });
    this.handleChangeRate = rate => this.setState({ rate });
    this.handleChangeTerm = term => this.setState({ term });
    this.handleChangeStartYear = startYear => this.setState({ startYear });
    this.handleChangeStartMonth = startMonth => this.setState({ startMonth });
  }

  componentDidMount() {
    this.setState({ showAmortization: true });
  }

  render() {
    const {
      amount,
      rate,
      term,
      startYear,
      startMonth,
      showAmortization
    } = this.state;
    const balanceStart = parseFloat(amount.replace(/,/g, ""));
    const periodCount = parseFloat(term) * 12;
    const periodInterest = rate ? parseFloat(rate) / 1200 : 0;
    const payment =
      periodInterest > 0
        ? (periodInterest * balanceStart) /
          (1 - Math.pow(1 + periodInterest, -periodCount))
        : balanceStart / periodCount;
    return (
      <Layout title="Loan Calculator">
        <Row>
          <div className="col-7">
            <Text
              label="Loan amount"
              prepend="$"
              value={amount}
              onChange={this.handleChangeAmount}
            />
          </div>
          <Col>
            <Text
              label="Annual Rate"
              value={rate}
              append="%"
              onChange={this.handleChangeRate}
            />
          </Col>
        </Row>
        <Row>
          <div className="col-5">
            <Text
              label="Term in years"
              value={term}
              maxLength="2"
              onChange={this.handleChangeTerm}
            />
          </div>
          <Col>
            <Text
              label="Start Year"
              value={startYear}
              maxLength="4"
              onChange={this.handleChangeStartYear}
            />
          </Col>
          <Col>
            <Select
              label="Start Month"
              value={startMonth}
              options={MONTHS}
              onChange={this.handleChangeStartMonth}
            />
          </Col>
        </Row>
        <Section>
          Monthly payment:{" "}
          <Dollars className="font-weight-bold" value={payment} />
        </Section>
        {showAmortization && (
          <Amortization
            balanceStart={balanceStart}
            periodCount={periodCount}
            periodInterest={periodInterest}
            payment={payment}
            {...this.state}
          />
        )}
      </Layout>
    );
  }
}

class Amortization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeYear: null
    };
  }

  render() {
    let {
      balanceStart,
      periodCount,
      periodInterest,
      payment,
      startYear,
      startMonth
    } = this.props;
    const { activeYear } = this.state;
    startYear = parseInt(startYear) || new Date().getFullYear();
    if (startYear <= 99) startYear += 1900;
    startMonth = parseInt(startMonth);
    let balance = balanceStart;
    const payments = [];
    let annual = {
      key: startYear,
      balance: 0,
      interest: 0,
      principal: 0,
      year: startYear,
      month: null
    };
    let key = 0;
    let preiodRemaining = periodCount;
    while (preiodRemaining-- > 0) {
      const interest = balance * periodInterest;
      const principal = payment - interest;
      balance += interest;
      balance = balance - payment;
      if (balance <= 0 && balance > -0.01) {
        balance = 0;
      }
      let year = startYear + Math.floor(key / 12);
      let month = (key % 12) + startMonth;
      if (month >= 12) {
        month = month % 12;
        year++;
      }
      if (annual.year !== year) {
        payments.push(annual);
        annual = {
          key: year,
          balance: 0,
          interest: 0,
          principal: 0,
          year: year,
          month: null
        };
      }
      annual.balance = balance;
      annual.interest += interest;
      annual.principal += principal;
      payments.push({ key, balance, interest, principal, year, month });
      key++;
    }
    payments.push(annual);
    return (
      <table className="table table-sm table-borderless text-nowrap table-hover">
        <thead className="border-bottom">
          <tr>
            <th />
            <th className="text-right">Principal</th>
            <th className="text-right">Interest</th>
            <th className="text-right">Balance</th>
          </tr>
        </thead>
        <tbody>
          {payments
            .filter(p =>
              p.year === activeYear ? p.month !== null : p.month === null
            )
            .map(p => (
              <tr
                className={classnames(
                  p.month !== null ? "text-secondary" : null
                )}
                style={{ cursor: "pointer" }}
                key={p.key}
                onClick={() =>
                  this.setState({
                    activeYear: activeYear !== p.year ? p.year : null
                  })
                }
              >
                <td>
                  {p.year} {p.month >= 0 ? MONTHS[p.month] : ""}
                </td>
                <td className="text-monospace text-right">
                  <Dollars value={p.principal} />
                </td>
                <td className="text-monospace text-right">
                  <Dollars value={p.interest} />
                </td>
                <td className="text-monospace text-right">
                  <Dollars value={p.balance} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
}

function Dollars({ value, className }) {
  return (
    <span className={className}>${currencyFormatter.format(value, "USD")}</span>
  );
}

export default LoanPage;
