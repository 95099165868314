import React from "react";
import Link from "gatsby-link";

const Header = ({ title }) => (
  <div
    style={{
      background: "#663399",
      height: "3rem",
      padding: ".5rem",
      margin: "0 0 1rem 0"
    }}
  >
    <Link
      to="/"
      style={{
        color: "white",
        textDecoration: "none",
        height: "2rem"
      }}
    >
      <svg
        style={{
          width: "2rem",
          height: "2rem",
          float: "left",
          marginRight: ".5rem"
        }}
        viewBox="0 0 100 100"
      >
        <path
          fill="white"
          d="
          M  10  90
          l  15  0
          l  30 -80
          l -15  0
          z
          M  45  90
          l  15  0
          l  30 -80
          l -15  0
          z
        "
        />
      </svg>
      <span style={{ fontSize: "1.8rem", lineHeight: "2rem" }}>{title}</span>
    </Link>
  </div>
);

export default Header;
