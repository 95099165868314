import React, { useState } from "react";
import { Row, Col } from "../../components/Grid";
import Text from "../../components/Text";
import Layout from "../../components/Layout";

export default function NatoPage(): React.ReactElement {
  const [phrase, setPhrase] = useState("Hello");
  return (
    <Layout title="NATO phonetic alphabet">
      <Row>
        <div className="col-12">
          <Text
            label="Phrase to spell"
            value={phrase}
            onChange={(v) => setPhrase(v)}
          />
        </div>
      </Row>
      {Array.prototype.map.call(phrase, (c, idx) => {
        if (c === " ") {
          return <hr />;
        }
        const item = MAP[c.toUpperCase()] ?? [c, ""];
        return (
          <Row key={idx}>
            <div className="col-1 text-success">{c}</div>
            <div className="col-6">{item[0]}</div>
            <div className="col-5 text-info">{item[1]}</div>
          </Row>
        );
      })}
    </Layout>
  );
}

const MAP = {
  A: ["Alfa", "AL FAH"],
  B: ["Bravo", "BRAH VOH"],
  C: ["Charlie", "CHAR LEE"],
  D: ["Delta", "DELL TAH"],
  E: ["Echo", "ECK OH"],
  F: ["Foxtrot", "FOKS TROT"],
  G: ["Golf", "GOLF"],
  H: ["Hotel", "HO TELL"],
  I: ["India", "IN DEE AH"],
  J: ["Juliett", "JEW LEE ETT"],
  K: ["Kilo", "KEY LOH"],
  L: ["Lima", "LEE MAH"],
  M: ["Mike", "MIKE"],
  N: ["November", "NO VEM BER"],
  O: ["Oscar", "OSS CAH"],
  P: ["Papa", "PAH PAH"],
  Q: ["Quebec", "KEH BECK"],
  R: ["Romeo", "ROW ME OH"],
  S: ["Sierra", "SEE AIR RAH"],
  T: ["Tango", "TANG GO"],
  U: ["Uniform", "YOU NEE FORM"],
  V: ["Victor", "VIK TAH"],
  W: ["Whiskey", "WISS KEY"],
  X: ["X-ray", "ECKS RAY"],
  Y: ["Yankee", "YANG KEY"],
  Z: ["Zulu", "ZOO LOO"],
  "0": ["Zero, nadazero", "ZE-RO"],
  "1": ["One, unaone", "WUN"],
  "2": ["Two, bissotwo", "TOO"],
  "3": ["Three, terrathree", "TREE"],
  "4": ["Four, kartefour", "FOW-er"],
  "5": ["Five, pantafive", "FIFE"],
  "6": ["Six, soxisix", "SIX"],
  "7": ["Seven, setteseven", "SEV-en"],
  "8": ["Eight, oktoeight", "AIT"],
  "9": ["Nine, novenine", "NIN-er"],
};
