import React from "react";
import uniqueId from "./uniqueId";

type Props = {
  value: string;
  valid: boolean;
  parse?: (string) => string;
  onChange: (string) => void;
  validate?: (string) => boolean;
  format?: (string) => string;
  children?: Array<any>;
  prepend?: string;
  append?: string;
  maxLength?: number;
  label?: any;
};

type State = {
  valid: boolean;
  invalidValue?: string;
  propsValue?: string;
};

class Text extends React.Component<Props, State> {
  static defaultProps = {
    onChange: () => {},
    valid: null,
    value: "",
  };

  id = uniqueId("text-");

  constructor(props: Props) {
    super(props);
    this.state = {
      valid: true,
      invalidValue: null,
    };

    this.handleChange = (e) => {
      const { parse } = this.props;
      const value = e.target.value;
      const valid = this.validate(value);
      const invalidValue = valid ? null : value;
      if (valid) {
        this.props.onChange(parse ? parse(value) : value);
      }
      this.setState({ valid, invalidValue });
    };
  }

  handleChange: ({ target: HTMLInputElement }) => void;

  validate(v: string): boolean {
    const { validate, parse, format } = this.props;
    try {
      if (validate) {
        return validate(v);
      }
      if (parse && format) {
        return v === format(parse(v));
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // if the parent changes the props.value,
    // reset the intermidate invalidness
    if (props.value !== state.propsValue) {
      return {
        propsValue: props.value,
        invalidValue: null,
        valid: true,
      };
    }
    return null;
  }

  render() {
    const { invalidValue } = this.state;
    const { value, format, children, prepend, append, maxLength } = this.props;
    const formatted = invalidValue || (format ? format(value) : value);
    let { valid } = this.props;
    if (valid === null) {
      if (!this.state.valid) {
        valid = false;
      }
    }
    let inputClass = "form-control";
    if (valid !== null) {
      inputClass += valid ? " is-valid" : " is-invalid";
    }
    return (
      <div className="form-group">
        <label htmlFor={this.id} className="text-nowrap">
          {this.props.label}
        </label>
        <div className="input-group">
          {prepend ? (
            <div className="input-group-prepend">
              <div className="input-group-text">{prepend}</div>
            </div>
          ) : null}
          <input
            type="text"
            className={inputClass}
            id={this.id}
            value={formatted}
            maxLength={maxLength || null}
            onChange={this.handleChange}
          />
          {append ? (
            <div className="input-group-append">
              <div className="input-group-text">{append}</div>
            </div>
          ) : null}
        </div>
        {children}
      </div>
    );
  }
}

export default Text;
