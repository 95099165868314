import React from "react";
import moment from "moment-timezone";
import Text from "../../components/Text";
import Select from "../../components/Select";
import Layout from "../../components/Layout";

class EpochPage extends React.Component {
  constructor(props) {
    super(props);
    const epochMs = null;
    this.state = { epochMs };
    this.handleChange = epochMs => this.setState({ epochMs });
  }

  componentDidMount() {
    const epochMs = Math.floor(moment().valueOf() / 1000) * 1000;
    this.setState({ epochMs });
  }

  render() {
    const { epochMs } = this.state;
    return (
      <Layout title="Epoch Time Converter">
        {epochMs != null && (
          <Epoch epochMs={epochMs} onChange={this.handleChange} />
        )}
        {epochMs != null && (
          <Formatted epochMs={epochMs} onChange={this.handleChange} />
        )}
      </Layout>
    );
  }
}

const utcFormat = "YYYY-MM-DD HH:mm:ss.SSS";

class Epoch extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = epochMs => this.props.onChange(epochMs);
  }

  render() {
    const { epochMs } = this.props;
    return (
      <div>
        <Text
          label="Zulu Time"
          value={epochMs}
          format={v =>
            moment(v)
              .utc()
              .format(utcFormat)
          }
          parse={v => moment(v + " +0000", utcFormat + " Z").valueOf()}
          onChange={this.handleChange}
        />
        <Text
          label="Epoch Seconds"
          value={epochMs}
          format={v => "" + Math.floor(v / 1000)}
          parse={v => parseInt(v) * 1000 + (epochMs % 1000)}
          onChange={this.handleChange}
        />
        <Text
          label="Epoch Milliseconds"
          value={epochMs}
          format={v => "" + v}
          parse={v => parseInt(v)}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

function formatEpochMs(epochMs, format, zone) {
  const m = moment.tz(epochMs, zone);
  if (m.isValid()) {
    return m.format(format);
  } else {
    throw new Error("Invalid Date");
  }
}

function parseEpochMs(formatted, format, zone) {
  const m = moment(formatted, format);
  if (m.isValid()) {
    return m.valueOf();
  } else {
    throw new Error("Invalid Date");
  }
}

function validateEpochMs(formatted, format, zone) {
  try {
    const epochMs = parseEpochMs(formatted, format, zone);
    formatEpochMs(epochMs, format, zone);
    return epochMs; // formatted === expected
  } catch (e) {
    return null;
  }
}

class Formatted extends React.Component {
  constructor(props) {
    super(props);
    const { epochMs } = props;
    const format = "YYYY-MM-DDTHH:mm:ssZ";
    const zone = moment.tz.guess();
    const formatted = formatEpochMs(epochMs, format, zone);
    this.state = { format, zone, formatted };

    this.handleChange = formatted => {
      const { format, zone } = this.state;
      this.setState({ formatted });
      const epochMs = validateEpochMs(formatted, format, zone);
      if (epochMs !== null) {
        this.props.onChange(epochMs);
      }
    };

    this.handleChangeFormat = format => {
      const { epochMs } = this.props;
      const { zone } = this.state;
      const formatted = formatEpochMs(epochMs, format, zone);
      this.setState({ format, formatted });
    };

    this.handleChangeZone = zone => {
      const { epochMs } = this.props;
      const { format } = this.state;
      const formatted = formatEpochMs(epochMs, format, zone);
      this.setState({ zone, formatted });
    };

    this.validate = formatted => {
      const { format, zone } = this.state;
      const epochMs = validateEpochMs(formatted, format, zone);
      return epochMs !== null;
    };
  }

  static getDerivedStateFromProps(props, state) {
    const epochMs = props.epochMs;
    if (state.propEpochMs !== epochMs) {
      const { format, zone } = state;
      return {
        propEpochMs: epochMs,
        formatted: moment.tz(epochMs, zone).format(format)
      };
    }
    return null;
  }

  render() {
    const { format, zone, formatted } = this.state;
    return (
      <div>
        <Select
          label="Time Zone"
          value={zone}
          options={moment.tz.names()}
          onChange={this.handleChangeZone}
        />
        <Text
          label="Formatted"
          value={formatted}
          validate={this.validate}
          onChange={this.handleChange}
        >
          <SuggestedFormat
            formatted={formatted}
            format={format}
            onChangeFormat={this.handleChangeFormat}
          />
        </Text>
        <Text
          label="Format"
          value={format}
          onChange={this.handleChangeFormat}
        />
      </div>
    );
  }
}

const suggestedFormats = [
  "YYYY-MM-DD",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH:mm:ss"
];

class SuggestedFormat extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeFormat = format => this.props.onChangeFormat(format);
  }

  render() {
    const { formatted, format } = this.props;
    const matchingFormats = suggestedFormats.filter(f => {
      if (f === format) return false;
      return moment(formatted, f).format(f) === formatted;
    });
    return (
      <div>
        {matchingFormats.length > 0 ? (
          <small>
            Format:{" "}
            {matchingFormats.map((f, i) => (
              <span key={f}>
                {i > 0 ? ", " : null}
                <button
                  className="btn btn-link btn-sm"
                  onClick={v => this.handleChangeFormat(f)}
                >
                  {f}
                </button>
              </span>
            ))}
          </small>
        ) : null}
      </div>
    );
  }
}

export default EpochPage;
