import React from "react";
import uniqueId from "./uniqueId";

type Props = {
  label: string;
  value: string;
};

class Checkbox extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.id = uniqueId("cb-");
    this.handleChange = e => this.props.onChange(e.target.checked);
  }

  render() {
    return (
      <div className="form-check form-check-inline">
        <input
          type="checkbox"
          className="form-check-input"
          id={this.id}
          checked={!!this.props.value}
          onChange={this.handleChange}
        />
        <label className="form-check-label" htmlFor={this.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
