import * as React from "react";
import Layout from "../../components/Layout";
import myers from "myers-diff";
import "./diff.css";

function DiffPage(): React.Node {
  const [left, setLeft] = React.useState(
    "Something similar\nSomething different\nAlso similar"
  );
  const [right, setRight] = React.useState(
    "Something similar\nSomething very different\nAlso similar"
  );
  const [leftDiff, rightDiff] = React.useMemo(() => {
    const leftDiff = [];
    const rightDiff = [];
    const diff = myers.diff(left, right);
    for (const { lhs, rhs } of diff) {
      leftDiff.push(lhs);
      rightDiff.push(rhs);
    }
    return [leftDiff, rightDiff];
  }, [left, right]);
  return (
    <Layout title="Diff">
      <div>
        <Section
          style={{ marginRight: "0.2%" }}
          text={left}
          diff={leftDiff}
          onChange={setLeft}
        />
        <Section
          style={{ marginLeft: "0.2%" }}
          text={right}
          diff={rightDiff}
          onChange={setRight}
        />
      </div>
    </Layout>
  );
}

function Section({ style, text, diff, onChange }) {
  const classes = [];
  for (let delta of diff) {
    applyChange(classes, "sectionAdd", delta.at, delta.add);
    applyChange(classes, "sectionDel", delta.at, delta.del);
  }
  const lines = text.split("\n");
  const html = lines
    .map((line, index) => {
      const sectonClass = ["section"];
      classes[index] && sectonClass.push(classes[index]);
      return `<div class="${sectonClass.join(" ")}">${"_".repeat(
        Math.max(line.length, 1)
      )}</div>`;
    })
    .join("\n");
  return (
    <div className="sectionRoot" style={style}>
      <div
        className="sectionInner sectionHTML"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <textarea
        className="sectionInner sectionText"
        rows={lines.length}
        cols={Math.max(...lines.map(l => l.length))}
        spellCheck={false}
        onChange={e => onChange(e.target.value)}
        value={text}
      />
    </div>
  );
}

function applyChange(
  classes: Array<string>,
  className: string,
  at: number,
  length?: number
): void {
  if (length != null && length > 0) {
    for (let i = at; i < at + length; i++) {
      classes[i] = className;
    }
  }
}

export default DiffPage;
