import React from "react";
import Link from "gatsby-link";
import Section from "../components/Section";
import Layout from "../components/Layout";

const IndexPage = () => (
  <Layout title="devizm.com">
    <Section>
      <p>Microtools for software engineering and beyond.</p>
      <div className="list-group">
        <ToolLink
          name="epoch"
          label="Epoch Time Converter"
          details="Convert between unix time stamp and formatted local date-time."
        />
        <ToolLink
          name="expression"
          label="Numerical Expression"
          details="Like a calculator, but better."
        />
        <ToolLink
          name="regexp"
          label="Regular Expression"
          details="Test bed for regualar expressions with as-you-type evaluation."
        />
        <ToolLink
          name="diff"
          label="Text Diff"
          details="Compare two text blobs"
        />
        <ToolLink
          name="nato"
          label="NATO phonetic alphabet"
          details="Helps you spell names over the phone."
        />
        <ToolLink
          name="loan"
          label="Loan Calculator"
          details="See your monthly payment and amortization schedule."
        />
      </div>
    </Section>
  </Layout>
);

const ToolLink = ({ name, label, details }) => (
  <Link
    className="list-group-item list-group-item-action"
    to={"/t/" + name + "/"}
    style={{ lineHeight: 1 }}
  >
    <div className="text-primary m-0">{label}</div>
    <small className="text-secondary">{details}</small>
  </Link>
);

export default IndexPage;
