import React from "react";
import Text from "../../components/Text";
import Section from "../../components/Section";
import Layout from "../../components/Layout";
import Checkbox from "../../components/Checkbox";
import uniqueId from "../../components/uniqueId";

class RegExpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regexp: "(the|dog)",
      global: true,
      ignoreCase: false
    };

    this.handleChangeRegExp = regexp => this.setState({ regexp });
    this.handleChangeGlobal = global => this.setState({ global });
    this.handleChangeIgnoreCase = ignoreCase => this.setState({ ignoreCase });
  }

  render() {
    let { regexp, global, ignoreCase } = this.state;
    let error = "";
    let re = null;
    try {
      let flags = "";
      if (global) {
        flags += "g";
      }
      if (ignoreCase) {
        flags += "i";
      }
      re = new RegExp(regexp, flags);
    } catch (e) {
      error = "" + e;
    }

    return (
      <Layout title="Regular Expression">
        <Text
          label="Regular Expression"
          value={regexp}
          onChange={this.handleChangeRegExp}
        >
          <div className="form-group">
            <Checkbox
              label="Global"
              value={global}
              onChange={this.handleChangeGlobal}
            />
            <Checkbox
              label="Ignore case"
              value={ignoreCase}
              onChange={this.handleChangeIgnoreCase}
            />
          </div>
        </Text>
        {error ? <div className="alert alert-warning">{error}</div> : null}
        <TestString
          label="Test String Alpha"
          testString="The quick brown fox jumps over the lazy dog"
          regexp={re}
        />
        <TestString
          label="Test String Beta"
          testString="A quick brown fox jumps over a lazy horse"
          regexp={re}
        />
      </Layout>
    );
  }
}

class TestString extends React.Component {
  constructor(props) {
    super(props);
    const { testString } = props;
    this.state = { testString };
    this.id = uniqueId("text-");
    this.handleTestStringChange = e =>
      this.setState({ testString: e.target.value });
  }

  render() {
    const { regexp, label } = this.props;
    const { testString } = this.state;
    let result = null;
    let mark = null;
    let inputClass = "form-control";
    if (regexp != null) {
      let matches = testString.match(regexp);
      if (Array.isArray(matches)) {
        matches = (
          <ol>
            {matches.map((v, idx) => (
              <li key={idx}>{v}</li>
            ))}
          </ol>
        );
      } else if (matches == null) {
        matches = <strong>none</strong>;
      }
      result = <Section>Matches: {matches}</Section>;
      if (regexp.test(testString)) {
        inputClass += " is-valid";
        mark = (
          <div className="input-group-append">
            <div
              className="input-group-text alert-success"
              style={{ width: "4.2rem" }}
            >
              PASS
            </div>
          </div>
        );
      } else {
        inputClass += " is-invalid";
        mark = (
          <div className="input-group-append">
            <div
              className="input-group-text alert-danger"
              style={{ width: "4.2rem" }}
            >
              FAIL
            </div>
          </div>
        );
      }
    }
    return (
      <div className="form-group">
        <label htmlFor={this.id}>{label}</label>
        <div className="input-group">
          <input
            type="text"
            className={inputClass}
            id={this.id}
            value={testString}
            onChange={this.handleTestStringChange}
          />
          {mark}
        </div>
        {result}
      </div>
    );
  }
}

export default RegExpPage;
