import * as React from "react";
import Header from "./Header";
import HelmetDefault from "./HelmetDefault";

import "./browser-window";
import "bootstrap/dist/css/bootstrap.min.css";

const Layout = ({
  children,
  title
}: {
  children: React.Node;
  title: string;
}) => (
  <div>
    <HelmetDefault />
    <Header title={title} />
    <div className="mx-md-5">
      <div style={{ margin: "0 0.2rem 0 0.2rem" }}>{children}</div>
    </div>
  </div>
);

export default Layout;
