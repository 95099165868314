import * as React from "react";
import uniqueId from "./uniqueId";

function Select({
  label,
  value,
  options,
  onChange
}: {
  label: string;
  value: string;
  options: { [string]: React.Node } | Array<string>;
  onChange: (string) => void;
}): React.Node {
  const id = React.useMemo(() => uniqueId("select-"), []);
  const entries: Array<[string, React.Node]> = Array.isArray(options)
    ? options.map(v => [v, v])
    : Object.entries(options);
  return (
    <div className="form-group">
      <label htmlFor={id} className="text-nowrap">
        {label}
      </label>
      <select
        id={id}
        className="form-control"
        value={value.toString()}
        onChange={e => onChange(e.target.value)}
      >
        {entries.map(([value, optionLabel]) => (
          <option key={value} value={value}>
            {optionLabel}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
