import React from "react";
import moment from "moment";
import Layout from "../../components/Layout";
import { Calendar } from "../../components/Calendar";

class DurationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout title="Duration">
        <Calendar firstDay={moment()} />
      </Layout>
    );
  }
}

export default DurationPage;
