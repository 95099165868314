import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const HelmetDefault = () => (
  <StaticQuery
    query={graphql`
         query LayoutQuery {
           site {
             siteMetadata {
               title
             }
           }
         }
       `}
    render={data => (
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'Microtools for software engineering and beyond. Convert between unix timestamp and formatted local date or tweak your regular expression without the extra button clicks.' },
          { name: 'keywords', content: 'microtool, dev, tools, epoch, regexp, regular, expression, regular expression, loan, calculator, unix, timestamp, seconds, milliseconds' },
          { name: 'theme-color', content: '#663399' },
          { property: 'og:url', content: 'https://www.devizm.com/' },
          { property: 'og:image', content: 'https://www.devizm.com/media/icon-256.png' },
          { property: 'og:image:width', content: '256' },
          { property: 'og:image:height', content: '256' },
          { property: 'og:title', content: 'Microtools for software engineering and beyond' },
          { property: 'og:description', content: 'Convert between unix timestamp and formatted local date or tweak your regular expression without the extra button clicks.' },
          { property: 'og:type', content: 'website' },
          { property: 'fb:app_id', content: '677584029274341' }
        ]}
      />
    )} />
)

export default HelmetDefault
